const composeKeys = (topLevelKey: string) => {
  const all = (key: string) => [key]
  const indexes = (key: string) => [...all(key), 'index']
  const index = (key: string) => (id: string | number) =>
    [...indexes(key), id?.toString()]
  const details = (key: string) => [...all(key), 'detail']
  const detail = (key: string) => (id: string | number) =>
    [...details(key), id?.toString()]

  // [key, index, uuid]

  return {
    all: all(topLevelKey),
    indexes: indexes(topLevelKey),
    index: index(topLevelKey),
    details: details(topLevelKey),
    detail: detail(topLevelKey),
  }
}

export const integrationsKeys = {
  ...composeKeys('integrations'),
}

export const entitlementGroupingsKeys = {
  ...composeKeys('entitlementGroupings'),
}

export const customerioACtivityKeys = {
  ...composeKeys('customerioACtivity'),
}

export const organizationHubspotNotesKeys = {
  ...composeKeys('organizationHubspotNotes'),
}

export const organizationsKeys = {
  ...composeKeys('organizations'),
  bulkUploads: {
    ...composeKeys('organizations/bulkUploads'),
  },
  directoryMappings: {
    ...composeKeys('organizations/directoryMappings'),
  },
  integrationConnections: {
    ...composeKeys('organizations/integrationConnections'),
  },
  phoneNumbers: {
    ...composeKeys('organizations/phoneNumbers'),
  },
}

export const workosOrganizationsKeys = {
  ...composeKeys('workosOrganizations'),
}

export const organizationSettingsKeys = {
  ...composeKeys('organizationSettings'),
}

export const stripeSubscriptionsKeys = {
  ...composeKeys('stripeSubscriptions'),
}

export const teamsKeys = {
  ...composeKeys('teams'),
  userTeams: {
    ...composeKeys('teams/userTeams'),
  },
}

export const unclaimedCardsKeys = {
  ...composeKeys('unclaimedCards'),
}

export const userLinqProductsQueryKeys = {
  ...composeKeys('userLinqProducts'),
}

export const usersQueryKeys = {
  ...composeKeys('users'),
  userTeams: {
    ...composeKeys('users/userTeams'),
  },
  appClipExperiences: {
    ...composeKeys('users/appClipExperiences'),
  },
}

export const zakekeOrderLineQueryKeys = {
  ...composeKeys('zakekeOrderLines'),
}

export const zakekeProductsKeys = {
  ...composeKeys('zakekeProducts'),
}

export const productJigTemplatesKeys = {
  ...composeKeys('productJigTemplates'),
}

export const userAuthConfirmationKeys = {
  ...composeKeys('userAuthConfirmation'),
}

export const bulkUploadsKeys = {
  ...composeKeys('bulkUploads'),
}

export const userContactsKeys = {
  ...composeKeys('userContacts'),
}

export const contactsKeys = {
  ...composeKeys('contacts'),
}

export const stripeCouponsKeys = {
  ...composeKeys('stripeCoupons'),
}

export const stripeProducts = {
  ...composeKeys('stripeProducts'),
}

export const stripeProductPrices = {
  ...composeKeys('stripeProductPrices'),
}

export const internalAdmins = {
  ...composeKeys('internalAdmins'),
}

export const userDeviceInfoKeys = {
  ...composeKeys('userDeviceInfos'),
}

export const userPushTokensKeys = {
  ...composeKeys('userPushTokens'),
}

export const organizationSubscriptionItemsKeys = {
  ...composeKeys('organizationSubscriptionItems'),
}

export const organizationProductCreditsKeys = {
  ...composeKeys('organizationProductCredits'),
}
