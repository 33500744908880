import { useMutation } from 'react-query'
import { store } from 'index'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'

interface DeleteMacMiniUser {
  id: string
}

const deleteMacMiniUser = async ({ id }: DeleteMacMiniUser) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v3/mac_mini_users/${id}`,
    {
      method: API_METHODS.DELETE,
      headers: apiHeaders(store.getState()),
    }
  )

  if (!response.ok) {
    const errorData = await response.json()
    const errorMessage =
      errorData.error?.messages?.[0] ||
      errorData.error?.message ||
      'Failed to delete Mac Mini User'
    throw new Error(errorMessage)
  }

  return true
}

const useDeleteMacMiniUser = () => {
  return useMutation(deleteMacMiniUser)
}

export default useDeleteMacMiniUser
