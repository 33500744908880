import { Breadcrumb, Card, Container, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router'
import useGetContactDetails from 'hooks/use-get-contact-details'

const ContactPageDetails = () => {
  const { contactId } = useParams<any>()
  const { data: contact, isLoading } = useGetContactDetails(contactId)
  return (
    <Container className="mb-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/contacts/:contactId">
          {contact?.name || `Contact Details`}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <h4>{contact?.full_name}</h4>
        </Card.Header>
        {!isLoading ? (
          <Row>
            <Col
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={contact?.image_url}
                alt={`${contact?.full_name}`}
                style={{ width: '100%' }}
              />
            </Col>
            <Col md={6}>
              <Card.Body>
                <Card.Text>
                  <strong>Phone:</strong>{' '}
                  {contact?.phone_number ? contact.phone_number : 'N/A'}
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong>{' '}
                  {contact?.email ? contact.email : 'N/A'}
                </Card.Text>
                <Card.Text>
                  <strong>Location:</strong>{' '}
                  {contact?.location ? contact.location : 'N/A'}
                </Card.Text>
                <Card.Text>
                  <strong>Company:</strong>{' '}
                  {contact?.company ? contact.company : 'N/A'}
                </Card.Text>
                <Card.Text>
                  <strong>Title:</strong>{' '}
                  {contact?.title ? contact.title : 'N/A'}
                </Card.Text>
                <Card.Text>
                  <strong>Contact Id:</strong>{' '}
                  {contact?.id ? contact.id : 'N/A'}
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        ) : (
          <Card.Text>loading....</Card.Text>
        )}
      </Card>
    </Container>
  )
}
export default ContactPageDetails
