import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { useQuery } from 'react-query'

const fetchMacMiniUsers = async () => {
  let response = await fetch(`${BASE_API_URL}/admin/api/v3/mac_mini_users`, {
    method: API_METHODS.GET,
    headers: apiHeaders(store.getState()),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json.data.mac_mini_users
}

const useGetMacMiniUsers = () => {
  return useQuery('macMiniUsers', fetchMacMiniUsers, {
    refetchOnWindowFocus: false,
    staleTime: 0,
  })
}

export default useGetMacMiniUsers
