import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import toast from 'react-hot-toast'

interface Params {
  friendly_name: string
}

const updateMacMini = async (id: string, apiParams: Params) => {
  let response = await fetch(`${BASE_API_URL}/admin/api/v3/mac_minis/${id}`, {
    method: API_METHODS.PUT,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify(apiParams),
  })
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUpdateMacMini = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ friendly_name }: { friendly_name: string }) => {
      const apiParams = {
        friendly_name,
      }
      return updateMacMini(id, apiParams)
    },
    {
      onError: () => {
        toast("Uh oh! this shouldn't happen. Shout in slack or report a bug.")
      },
      onSettled: () => {
        queryClient.invalidateQueries(['macMini', id])
      },
    }
  )
}

export default useUpdateMacMini
