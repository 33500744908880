import { useQuery } from 'react-query'
import { store } from 'index'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'

const fetchAppleIdByUserId = async (userId: string | number) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/apple_ids?user_id=${userId}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()

  return json.data.apple_ids && json.data.apple_ids.length > 0
    ? json.data.apple_ids[0]
    : null
}

const useGetAppleIdByUserId = (userId: string | number) => {
  return useQuery(
    ['apple_id_by_user_id', userId],
    () => fetchAppleIdByUserId(userId),
    {
      enabled: !!userId,
    }
  )
}

export default useGetAppleIdByUserId
