import React, { useState } from 'react'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { LinearClient } from '@linear/sdk'
import toast from 'react-hot-toast'
import CloudinaryUploadInput from 'components/cloudinary-upload-input'
import { useSelector } from 'react-redux'
import { RootState } from 'index'

const BugReporterPage = () => {
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [deviceDetails, setDeviceDetails] = useState<string>('')
  const [hubbleURL, setHubbleURL] = useState<string>('')
  const [loomVideoLink, setLoomVideoLink] = useState<string>('')
  const [email, setEmail] = useState<string | undefined>(currentUser?.email)
  const [priority, setPriority] = useState<number | null>(null)
  const [imageUrl, setImageUrl] = useState<string | undefined>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const linearClient = new LinearClient({
    apiKey: 'lin_api_EDNYNxBzlThAfie0gBX3JONs3GRgvEHdZPLUn5UT',
  })
  const bugSupportTeamID = '1dcd5a5c-2d79-4c06-8fd7-98de273a3fe5'
  const engineerSupportLabelID = 'ec7df152-0095-4d31-9817-77ce16c2e715'
  const bugLabelID = 'b4e64914-7dd7-4d5a-941f-1721ea747e44'

  const createIssue = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isLoading) return

    let error = false
    if (priority !== null && (priority < 0 || priority > 4)) {
      toast.error('Failed to report bug. Invalid priority selected.')
      error = true
    } else if (!title) {
      toast.error('Failed to report bug. Title is required.')
      error = true
    } else if (!description) {
      toast.error('Failed to report bug. Description is required.')
      error = true
    } else if (!email) {
      toast.error('Failed to report bug. Email is required.')
      error = true
    }

    if (error) return

    setIsLoading(true)

    const validImageExtension =
      imageUrl && imageUrl.match(/\.(jpeg|jpg|gif|png)$/) != null

    const markdownDoc = validImageExtension
      ? `# Description \n${description} \n# Image \n![Image](${imageUrl})`
      : `# Description \n${description}`

    const dueDate = new Date(Date.now() + (priority || 1) * 24 * 60 * 60 * 1000)

    const issuePayload = await linearClient.createIssue({
      teamId: bugSupportTeamID,
      title,
      priority,
      description: markdownDoc,
      labelIds: [bugLabelID, engineerSupportLabelID],
      dueDate,
      slaBreachesAt: dueDate,
    })

    if (issuePayload.success) {
      toast.success('Issue created successfully!')
      const issue = await issuePayload.issue
      const issueId = issue?.id
      if (issueId) {
        if (deviceDetails) {
          await linearClient.createComment({
            issueId,
            body: `Device Details: ${deviceDetails}`,
          })
        }

        await linearClient.createComment({
          issueId,
          body: `Created By: ${email}`,
        })

        if (hubbleURL) {
          const validatedHubbleURL = hubbleURL.includes('https://')
            ? hubbleURL
            : `https://${hubbleURL}`
          try {
            await linearClient.createAttachment({
              issueId,
              url: validatedHubbleURL,
              title: 'Hubble URL',
            })
          } catch (error) {
            toast.error(
              'Bug reported, but we failed to add the Hubble URL attachment. Please send @engineering a slack message!'
            )
          }
        }

        if (imageUrl) {
          // get extension from url
          const extension = imageUrl?.split('.').pop()
          await linearClient.createAttachment({
            issueId,
            url: imageUrl,
            title: `File Attachment (.${extension})`,
          })
        }

        if (loomVideoLink) {
          const validatedLoomURL = loomVideoLink.includes('https://')
            ? loomVideoLink
            : `https://${loomVideoLink}`
          try {
            await linearClient.createAttachment({
              issueId,
              url: validatedLoomURL,
              title: 'Loom Video',
            })
          } catch (error) {
            toast.error(
              'Bug reported, but we failed to add the Loom Video attachment. Please send @engineering a slack message!'
            )
          }
        }
      }
      setPriority(null)
      setTitle('')
      setDescription('')
      setHubbleURL('')
      setDeviceDetails('')
      setLoomVideoLink('')
      setImageUrl(undefined)
    } else {
      toast.error('Failed to report bug. Send @engineering a slack message!')
      return
    }

    setIsLoading(false)
  }

  return (
    <Container>
      <h1 className="text-center">Submit Linq Engineering Issue</h1>

      <div className="mb-3 text-center">
        Details of this form will be submitted to the Eng Support Queue project
        in Asana. Please fill out all details as accurately as possible.
      </div>

      {isLoading ? (
        <div className="m-2">Loading...</div>
      ) : (
        <Form onSubmit={createIssue} className="border border-1 p-4 rounded-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Bug Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Description</Form.Label>
                <div>
                  <Form.Text className="text-muted">
                    Include as many relevant details as possible, a link to a
                    Slack thread for context, or a loom video link.
                  </Form.Text>
                </div>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="loomVideoLink">
                <Form.Label>Loom Video Link</Form.Label>
                <div>
                  <Form.Text className="text-muted">
                    Please add a Loom video - the best way to make sure bugs get
                    fixed is to provide as much info as possible.
                  </Form.Text>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Enter Loom Video URL"
                  onChange={(e) => setLoomVideoLink(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Hubble URL</Form.Label>
                <div>
                  <Form.Text className="text-muted">
                    Hubble URL of the user or organization experiencing the
                    issue.
                  </Form.Text>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Hubble URL"
                  onChange={(e) => setHubbleURL(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Device Details</Form.Label>
                <div>
                  <Form.Text className="text-muted">
                    Include any information about the device and/or app version
                    the issue is occurring on.
                  </Form.Text>
                </div>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Device Details"
                  onChange={(e) => setDeviceDetails(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Dropdown className="mb-3">
                <Form.Label>
                  Priority ({' '}
                  <Form.Text className="text-muted">
                    <a
                      href="https://www.notion.so/Bug-Reporting-and-Triaging-ad28cb4d7b464eb29eca7e7b3815a480?pvs=4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Notion Doc explaining priorities
                    </a>
                  </Form.Text>{' '}
                  )
                </Form.Label>
                <div>
                  <Dropdown.Toggle variant="success" className="mt-1">
                    {priority === 1
                      ? 'S0 - Urgent'
                      : priority === 2
                      ? 'S1 - High'
                      : priority === 3
                      ? 'S2 - Medium'
                      : priority === 4
                      ? 'S4 - Low'
                      : priority === 0
                      ? 'No priority'
                      : 'Select Priority'}
                  </Dropdown.Toggle>
                </div>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setPriority(1)}>
                    S0 - Urgent
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPriority(2)}>
                    S1 - High
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPriority(3)}>
                    S2 - Medium
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPriority(4)}>
                    S4 - Low
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPriority(0)}>
                    No priority
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Email Address</Form.Label>
                <div>
                  <Form.Text className="text-muted">
                    This is your linqapp.com email address, don't put a
                    customer's email here.
                  </Form.Text>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="imageUrl">
                <Form.Label>Screenshot/Video File Upload</Form.Label>
                <div>
                  <Form.Text className="text-muted">
                    (Include a relevant screenshot or screen recording here if
                    possible)
                  </Form.Text>
                </div>
                <CloudinaryUploadInput
                  inputLabel=""
                  imageUrl={imageUrl}
                  onImageUrlChange={(value) => {
                    setImageUrl(value)
                  }}
                />
              </Form.Group>
            </Col>
            <Button variant="primary" type="submit" size="lg" className="w-100">
              Submit
            </Button>
          </Row>
        </Form>
      )}
    </Container>
  )
}

export default BugReporterPage
