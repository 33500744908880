import React, { useState } from 'react'
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap'
import useUpdateOrganizationOrganizationSubscriptionId from 'hooks/use-update-organization-subscription'
import useCreateOrganizationSubscription from 'hooks/use-create-organization-subscription'
import { Organization } from 'interfaces/organization'

export const UpdateOrgSubscriptionIdModal = ({
  show,
  handleClose,
  org,
  setShowSubscriptionIdUpdateModal,
}: {
  show: boolean
  handleClose: () => void
  org: Organization
  setShowSubscriptionIdUpdateModal: (show: boolean) => void
}) => {
  const [newSubscriptionId, setNewSubscriptionId] = useState('')
  const {
    mutate: updateOrgSubscriptionId,
    isLoading: isUpdatingOrgSubscriptionId,
  } = useUpdateOrganizationOrganizationSubscriptionId(org.id)

  const {
    mutate: createOrgSubscription,
    isLoading: isCreatingOrgSubscription,
  } = useCreateOrganizationSubscription(org.id)

  const onClose = () => {
    setNewSubscriptionId('')
    handleClose()
  }

  const handleUpdateOrgSubscriptionId = () => {
    if (org.organization_subscription) {
      updateOrgSubscriptionId(
        {
          subscription_id: newSubscriptionId,
        },
        {
          onSuccess: () => {
            setShowSubscriptionIdUpdateModal(false)
          },
        }
      )
    } else {
      createOrgSubscription(newSubscriptionId, {
        onSuccess: () => {
          setShowSubscriptionIdUpdateModal(false)
        },
      })
    }
  }
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>Add New Subscription Id</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 mt-4">
          <FormControl
            autoComplete="off"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewSubscriptionId(e.target.value)
            }
            type="text"
            placeholder="Enter new subscription id here"
            aria-label="new subscription id"
            aria-describedby="new subscription id"
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isUpdatingOrgSubscriptionId}
          onClick={handleUpdateOrgSubscriptionId}
        >
          Update
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
