import { useState } from 'react'
import useGetMacMiniUsers from 'hooks/use-get-mac-mini-users'
import { useParams, Link, useHistory } from 'react-router-dom'
import { MacMiniUser } from 'interfaces/mac-mini-user'
import {
  Container,
  Table,
  Card,
  Form,
  Button,
  Breadcrumb,
  Alert,
  FormControl,
} from 'react-bootstrap'
import useUpdateMacMiniUser from 'hooks/use-update-mac-mini-user'
import { toast } from 'react-hot-toast'
import useRefreshAppleId from 'hooks/use-refresh-apple-id'
import useDeleteMacMiniUser from 'hooks/use-delete-mac-mini-user'

const MacMiniUserDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { data: macMiniUsers, isLoading, error } = useGetMacMiniUsers()
  const [isEditMode, setIsEditMode] = useState(false)
  const [userFormData, setUserFormData] = useState<{ [key: string]: any }>({})
  const updateMacMiniUser = useUpdateMacMiniUser()
  const refreshAppleId = useRefreshAppleId()
  const deleteMacMiniUser = useDeleteMacMiniUser()
  const history = useHistory()

  const macMiniUser = macMiniUsers?.find(
    (user: MacMiniUser) => user.id.toString() === id
  )

  const getGrafanaLogsUrl = () => {
    if (!macMiniUser?.friendly_id) return ''

    const deviceId = encodeURIComponent(macMiniUser.friendly_id)

    return `https://linqinternal.grafana.net/a/grafana-lokiexplore-app/explore/device/${deviceId}/logs?var-ds=de8b5r6w43egwd&from=now-30d&to=now&var-filters=device%7C%3D%7C${deviceId}&var-fields=&var-levels=&patterns=%5B%5D&var-metadata=&var-patterns=&var-lineFilter=&refresh=&urlColumns=%5B%5D&visualizationType=%22logs%22&displayedFields=%5B%5D&timezone=America%2FChicago&var-all-fields=&var-lineFilterV2=&var-lineFilters=&sortOrder=%22Descending%22&wrapLogMessage=false`
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      const { name, checked } = e.target
      setUserFormData((prev) => ({
        ...prev,
        [name]: checked,
      }))
    } else {
      const { name, value } = e.target
      if (name === 'apple_id_id') {
        setUserFormData((prev) => ({
          ...prev,
          apple_id_id: parseInt(value),
        }))
      } else {
        setUserFormData((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    }
  }

  const handleSaveClick = async () => {
    try {
      await updateMacMiniUser.mutateAsync({
        id,
        data: {
          mac_mini_user: userFormData,
        },
      })

      toast.success('Mac Mini User updated successfully')
      setIsEditMode(false)
    } catch (error) {
      toast.error('Failed to update Mac Mini User')
      console.error('Error updating Mac Mini User:', error)
    }
  }

  const handleRefreshClick = async () => {
    try {
      await refreshAppleId.mutateAsync(Number(id))
      toast.success('Apple ID refreshed successfully')
    } catch (error) {
      toast.error('Failed to refresh Apple ID')
      console.error('Error refreshing Apple ID:', error)
    }
  }

  const handleDeleteClick = async () => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this Mac Mini User?'
    )
    if (isConfirmed) {
      try {
        await deleteMacMiniUser.mutateAsync({ id })
        toast.success('Mac Mini User deleted successfully')
        history.push('/blue')
      } catch (error) {
        toast.error('Failed to delete Mac Mini User')
        console.error('Error deleting Mac Mini User:', error)
      }
    }
  }

  if (isLoading) {
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    )
  }

  if (error) {
    const errorMessage =
      (error as Error)?.message || 'An unknown error occurred'
    return (
      <Container>
        <div>Error: {errorMessage}</div>
      </Container>
    )
  }

  if (!macMiniUser) {
    return (
      <Container>
        <div>User not found</div>
      </Container>
    )
  }

  return (
    <Container>
      {macMiniUser.apple_id && !macMiniUser.apple_id.active && (
        <Alert variant="danger" className="mt-3">
          APPLE ID IS INACTIVE FOR THIS USER
        </Alert>
      )}
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/blue">Blue</Breadcrumb.Item>
        <Breadcrumb.Item href={`/mac-mini/${macMiniUser?.mac_mini.id}`}>
          Mac Mini {macMiniUser?.mac_mini.friendly_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {macMiniUser.friendly_id.split('-').pop()?.toUpperCase() ||
            macMiniUser.friendly_id}{' '}
          Details
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mt-4">
        <Card.Header>Mac Mini User Details</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive variant="dark">
            <tbody>
              <tr>
                <th>Mac Mini Username</th>
                <td>
                  {macMiniUser.friendly_id.split('-').pop()?.toUpperCase() ||
                    macMiniUser.friendly_id}
                </td>
              </tr>
              <tr>
                <th>Mac Mini</th>
                <td>
                  <Link
                    to={`/mac-mini/${macMiniUser.mac_mini.id}`}
                    style={{
                      color: 'light-green',
                      textDecoration: 'underline',
                    }}
                  >
                    {macMiniUser.mac_mini.friendly_name}
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>
                  {macMiniUser.apple_id ? (
                    <Link
                      to={`/apple-id/${macMiniUser.apple_id.id}`}
                      style={{
                        color: 'light-green',
                        textDecoration: 'underline',
                      }}
                    >
                      {macMiniUser.apple_id.phone_number || 'N/A'}
                    </Link>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
              <tr>
                <th>Linq User</th>
                <td>{macMiniUser.apple_id?.user?.name || 'N/A'}</td>
              </tr>
              <tr>
                <th>Server Port</th>
                <td>{macMiniUser.server_port || 'N/A'}</td>
              </tr>
              <tr>
                <th>Allow Injection</th>
                <td>
                  {isEditMode ? (
                    <Form.Check
                      type="checkbox"
                      name="allow_injection"
                      checked={
                        userFormData.allow_injection ??
                        macMiniUser.allow_injection
                      }
                      onChange={handleInputChange}
                    />
                  ) : macMiniUser.allow_injection ? (
                    'Yes'
                  ) : (
                    'No'
                  )}
                </td>
              </tr>
              <tr>
                <th>Auto Update</th>
                <td>
                  {isEditMode ? (
                    <Form.Check
                      type="checkbox"
                      name="auto_update"
                      checked={
                        userFormData.auto_update ?? macMiniUser.auto_update
                      }
                      onChange={handleInputChange}
                    />
                  ) : macMiniUser.auto_update ? (
                    'Yes'
                  ) : (
                    'No'
                  )}
                </td>
              </tr>
              <tr>
                <th>Proxy Service</th>
                <td>
                  {isEditMode ? (
                    <FormControl
                      as="select"
                      name="proxy_service"
                      value={
                        userFormData.proxy_service ?? macMiniUser.proxy_service
                      }
                      onChange={handleInputChange}
                    >
                      <option value="Cloudflare">Cloudflare</option>
                      <option value="Zrok">Zrok</option>
                    </FormControl>
                  ) : (
                    macMiniUser.proxy_service || 'N/A'
                  )}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="d-flex justify-content-between mt-3">
            <div>
              <Button
                onClick={() => {
                  if (isEditMode) {
                    setUserFormData({})
                    setIsEditMode(false)
                  } else {
                    setUserFormData({
                      allow_injection: macMiniUser.allow_injection,
                      auto_update: macMiniUser.auto_update,
                    })
                    setIsEditMode(true)
                  }
                }}
                variant={isEditMode ? 'secondary' : 'primary'}
                className="me-2"
              >
                {isEditMode ? 'Cancel' : 'Edit'}
              </Button>
              {isEditMode && (
                <Button
                  onClick={handleSaveClick}
                  variant="success"
                  className="me-2"
                >
                  Save
                </Button>
              )}
              <Button
                onClick={handleRefreshClick}
                variant="info"
                className="me-2"
              >
                Refresh Apple ID
              </Button>
              <Button
                as="a"
                href={getGrafanaLogsUrl()}
                target="_blank"
                rel="noopener noreferrer"
                variant="warning"
              >
                View Logs
              </Button>
            </div>
            <div>
              <Button onClick={handleDeleteClick} variant="danger">
                Delete
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default MacMiniUserDetailsPage
