import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Container,
  Table,
  Card,
  Button,
  Breadcrumb,
  Form,
} from 'react-bootstrap'
import { toast } from 'react-hot-toast'

import useGetMacMiniUsers from 'hooks/use-get-mac-mini-users'
import useUpdateMacMiniUser from 'hooks/use-update-mac-mini-user'
import useUpdateMacMini from 'hooks/use-update-mac-mini'

import { MacMiniUser } from 'interfaces/mac-mini-user'

const MacMiniDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { data: macMiniUsers, isLoading, error } = useGetMacMiniUsers()
  const [userFormData, setUserFormData] = useState<{
    [userId: string]: Pick<MacMiniUser, 'allow_injection' | 'auto_update'>
  }>({})
  const updateMacMiniUser = useUpdateMacMiniUser()
  const updateMacMini = useUpdateMacMini(id)

  const [editModeUsers, setEditModeUsers] = useState<Set<string>>(new Set())
  const [editModeName, setEditModeName] = useState(false)
  const [friendlyName, setFriendlyName] = useState('')
  const [macMiniUser, setMacMiniUser] = useState<MacMiniUser | undefined>(
    undefined
  )
  const [originalUserFormData, setOriginalUserFormData] = useState<{
    [userId: string]: Pick<MacMiniUser, 'allow_injection' | 'auto_update'>
  }>({})

  useEffect(() => {
    if (macMiniUsers) {
      const foundUser = macMiniUsers.find(
        (user: MacMiniUser) => user.mac_mini.id.toString() === id
      )
      setMacMiniUser(foundUser)
      if (foundUser) {
        setFriendlyName(foundUser.mac_mini.friendly_name)
        const initialFormData: {
          [userId: string]: Pick<MacMiniUser, 'allow_injection' | 'auto_update'>
        } = {}

        macMiniUsers.forEach((user: MacMiniUser) => {
          initialFormData[user.id] = {
            allow_injection: user.allow_injection,
            auto_update: user.auto_update,
          }
        })

        setUserFormData(initialFormData)
      }
    }
  }, [macMiniUsers, id])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: string
  ) => {
    const { name, checked, type } = e.target
    setUserFormData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        [name]: type === 'checkbox' ? checked : e.target.value,
      },
    }))
  }

  const toggleMacMiniUserEditMode = (userId: string) => {
    if (!editModeUsers.has(userId)) {
      setOriginalUserFormData((prevData) => ({
        ...prevData,
        [userId]: { ...userFormData[userId] },
      }))
    }

    setEditModeUsers((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(userId)) {
        newSet.delete(userId)
      } else {
        newSet.add(userId)
      }
      return newSet
    })
  }

  const handleCancelMacMiniUserEdit = (userId: string) => {
    setUserFormData((prevData) => ({
      ...prevData,
      [userId]: { ...originalUserFormData[userId] },
    }))

    setEditModeUsers((prev) => {
      const newSet = new Set(prev)
      newSet.delete(userId)
      return newSet
    })
  }

  const handleSaveMacMiniUserSettings = async (userId: string) => {
    try {
      if (!userFormData[userId]) {
        toast.error('No settings to update for this user')
        return
      }

      const settings = userFormData[userId]
      await updateMacMiniUser.mutateAsync({
        id: userId,
        data: {
          mac_mini_user: settings,
        },
      })

      toast.success(`Info updated for User ${userId}`)
      setEditModeUsers((prev) => {
        const newSet = new Set(prev)
        newSet.delete(userId)
        return newSet
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error occurred'
      toast.error(`Failed to update Mac Mini User: ${errorMessage}`)
      console.error('Error updating Mac Mini User:', error)
    }
  }

  const handleMacMiniNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFriendlyName(event.target.value)
  }

  const handleSaveMacMiniName = async () => {
    try {
      await updateMacMini.mutateAsync({ friendly_name: friendlyName })
      toast.success('Mac Mini name updated successfully')
      setEditModeName(false)
      if (macMiniUser) {
        setMacMiniUser({
          ...macMiniUser,
          mac_mini: {
            ...macMiniUser.mac_mini,
            friendly_name: friendlyName,
          },
        })
      }
    } catch (error) {
      toast.error('Failed to update Mac Mini name')
      console.error('Error updating Mac Mini name:', error)
    }
  }

  const handleCancelMacMiniNameEdit = () => {
    if (macMiniUser) {
      setFriendlyName(macMiniUser.mac_mini.friendly_name)
    }
    setEditModeName(false)
  }

  if (isLoading) {
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    )
  }

  if (error) {
    const errorMessage =
      (error as Error)?.message || 'An unknown error occurred'
    return (
      <Container>
        <div>Error: {errorMessage}</div>
      </Container>
    )
  }

  if (!macMiniUser) {
    return (
      <Container>
        <div>Mac Mini not found</div>
      </Container>
    )
  }

  const associatedUsers = macMiniUsers
    ?.filter((user: MacMiniUser) => user.mac_mini.id.toString() === id)
    ?.sort((a: MacMiniUser, b: MacMiniUser) => a.id - b.id)

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/blue">Blue</Breadcrumb.Item>
        <Breadcrumb.Item
          active
        >{`Mac Mini ${macMiniUser.mac_mini.friendly_name} Details`}</Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mt-4">
        <Card.Header>{`Mac Mini ${macMiniUser.mac_mini.friendly_name} Info`}</Card.Header>
        <Card.Body>
          <Button
            onClick={() => {
              if (editModeName) {
                handleCancelMacMiniNameEdit()
              } else {
                setEditModeName(true)
              }
            }}
            variant={editModeName ? 'secondary' : 'primary'}
            className="mb-4 me-2"
          >
            {editModeName ? 'Cancel' : 'Edit'}
          </Button>
          {editModeName && (
            <Button
              onClick={handleSaveMacMiniName}
              variant="success"
              className="mb-4"
            >
              Save
            </Button>
          )}
          <Table striped bordered hover responsive variant="dark">
            <tbody>
              <tr>
                <th>Mac Mini Name</th>
                <td>
                  {editModeName ? (
                    <input
                      type="text"
                      value={friendlyName}
                      onChange={handleMacMiniNameChange}
                    />
                  ) : (
                    macMiniUser.mac_mini.friendly_name
                  )}
                </td>
              </tr>
              <tr>
                <th>Device Identifier</th>
                <td>{macMiniUser.mac_mini.device_identifier}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{macMiniUser.mac_mini.status}</td>
              </tr>
              <tr>
                <th>Last Seen At</th>
                <td>
                  {new Date(macMiniUser.mac_mini.last_seen_at).toLocaleString()}
                </td>
              </tr>
              <tr>
                <th>OS Version</th>
                <td>{macMiniUser.mac_mini.os_version}</td>
              </tr>
              <tr>
                <th>Max Users</th>
                <td>{macMiniUser.mac_mini.max_users}</td>
              </tr>
              <tr>
                <th>Notes</th>
                <td>{macMiniUser.mac_mini.notes || 'N/A'}</td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>
                  {new Date(macMiniUser.mac_mini.created_at).toLocaleString()}
                </td>
              </tr>
              <tr>
                <th>Updated At</th>
                <td>
                  {new Date(macMiniUser.mac_mini.updated_at).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>{`Mac Mini ${macMiniUser.mac_mini.friendly_name} Users`}</Card.Header>
        <Card.Body>
          {associatedUsers && associatedUsers.length > 0 ? (
            <Table striped bordered hover responsive variant="dark">
              <thead>
                <tr>
                  <th> </th>
                  <th>Mac Mini Username</th>
                  <th>Phone Number</th>
                  <th>Linq User</th>
                  <th>Server Port</th>
                  <th>Allow Injection</th>
                  <th>Auto Update</th>
                </tr>
              </thead>
              <tbody>
                {associatedUsers.map((user: MacMiniUser) => (
                  <tr key={user.id}>
                    <td>
                      {!editModeUsers.has(user.id.toString()) ? (
                        <Button
                          onClick={() =>
                            toggleMacMiniUserEditMode(user.id.toString())
                          }
                          variant="primary"
                        >
                          Edit
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() =>
                              handleCancelMacMiniUserEdit(user.id.toString())
                            }
                            variant="secondary"
                            className="me-2"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() =>
                              handleSaveMacMiniUserSettings(user.id.toString())
                            }
                            variant="success"
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`/mac-mini-user/${user.id}`}
                        style={{
                          display: 'block',
                          color: 'light-green',
                          textDecoration: 'underline',
                        }}
                      >
                        {user.friendly_id.split('-').pop()?.toUpperCase() ||
                          user.friendly_id}
                      </Link>
                    </td>
                    <td>
                      {user.apple_id ? (
                        <Link to={`/apple-id/${user.apple_id.id}`}>
                          {user.apple_id.phone_number || 'N/A'}
                        </Link>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>{user.apple_id?.user?.name || 'N/A'}</td>
                    <td>{user.server_port || 'N/A'}</td>
                    <td>
                      {editModeUsers.has(user.id.toString()) ? (
                        <Form.Check
                          type="checkbox"
                          name="allow_injection"
                          checked={
                            userFormData[user.id]?.allow_injection || false
                          }
                          onChange={(e) =>
                            handleInputChange(e, user.id.toString())
                          }
                        />
                      ) : userFormData[user.id]?.allow_injection ? (
                        'Yes'
                      ) : (
                        'No'
                      )}
                    </td>
                    <td>
                      {editModeUsers.has(user.id.toString()) ? (
                        <Form.Check
                          type="checkbox"
                          name="auto_update"
                          checked={userFormData[user.id]?.auto_update || false}
                          onChange={(e) =>
                            handleInputChange(e, user.id.toString())
                          }
                        />
                      ) : userFormData[user.id]?.auto_update ? (
                        'Yes'
                      ) : (
                        'No'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No associated users found.</p>
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

export default MacMiniDetailsPage
