import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Container,
  Table,
  Form,
  Button,
  Breadcrumb,
  Alert,
} from 'react-bootstrap'
import useGetAppleId from 'hooks/use-get-apple-id-details'
import useUpdateAppleId from 'hooks/use-update-apple-id-details'
import useGetMacMiniUsers from 'hooks/use-get-mac-mini-users'
import toast from 'react-hot-toast'
import { AppleId } from 'interfaces/apple-id'
import { MacMiniUser } from 'interfaces/mac-mini-user'

const AppleIdPage = () => {
  const { id } = useParams<{ id: string }>()
  const { data: appleIdDetails, isLoading } = useGetAppleId(id)
  const { data: macMiniUsers } = useGetMacMiniUsers()
  const updateAppleId = useUpdateAppleId()
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    phone_number: '',
    user_id: '',
    active: false,
  })
  const [isEditMode, setIsEditMode] = useState(false)
  const [originalData, setOriginalData] = useState(formData)

  useEffect(() => {
    if (appleIdDetails) {
      const newFormData = {
        id: appleIdDetails.id.toString(),
        email: appleIdDetails.email,
        phone_number: appleIdDetails.phone_number,
        user_id: appleIdDetails.user_id,
        active: appleIdDetails.active,
      }
      setFormData(newFormData)
      setOriginalData(newFormData)
    }
  }, [appleIdDetails])

  // Find the relevant blue device user
  const blueDeviceUser = macMiniUsers?.find(
    (user: MacMiniUser) => user.apple_id?.id === parseInt(id)
  )

  const handleSaveClick = () => {
    const cleanedPhoneNumber = formData.phone_number.replace(/\D/g, '')
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    const isValidPhoneNumber =
      cleanedPhoneNumber.length === 10 ||
      (cleanedPhoneNumber.length === 11 && cleanedPhoneNumber.startsWith('1'))

    if (!isValidPhoneNumber) {
      toast.error(
        'Phone number must be 10 digits or 11 digits with a "+1" prefix.'
      )
      return
    }

    if (!emailPattern.test(formData.email)) {
      toast.error('Invalid email address. Please enter a valid email.')
      return
    }

    // Format the phone number to E.164
    const formattedPhoneNumber = `+1${cleanedPhoneNumber.slice(-10)}`

    const payload: Partial<AppleId> = {
      email: formData.email.trim(),
      phone_number: formattedPhoneNumber,
      user_id: parseInt(formData.user_id, 10),
      active: formData.active,
    }

    updateAppleId.mutate(
      {
        id: formData.id,
        data: payload,
      },
      {
        onSuccess: () => {
          toast.success('Apple ID updated successfully!')
          setIsEditMode(false)
          setFormData({
            ...formData,
            phone_number: formattedPhoneNumber,
          })
          setOriginalData({
            ...formData,
            phone_number: formattedPhoneNumber,
          })
        },
        onError: (error) => {
          toast.error('Failed to update Apple ID. Please try again.')
          console.error('Error updating Apple ID:', error)
        },
      }
    )
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleCancelClick = () => {
    setFormData(originalData)
    setIsEditMode(false)
  }

  return (
    <Container>
      {!appleIdDetails?.active && (
        <Alert variant="danger" className="mt-3">
          APPLE ID IS INACTIVE
        </Alert>
      )}
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/blue">Blue</Breadcrumb.Item>
        {blueDeviceUser && (
          <>
            <Breadcrumb.Item href={`/mac-mini/${blueDeviceUser.mac_mini.id}`}>
              Mac Mini {blueDeviceUser.mac_mini.friendly_name}
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/mac-mini-user/${blueDeviceUser.id}`}>
              {blueDeviceUser.friendly_id.split('-').pop()?.toUpperCase() ||
                blueDeviceUser.friendly_id}
            </Breadcrumb.Item>
          </>
        )}
        <Breadcrumb.Item active>
          {formData.phone_number || 'Apple ID Details'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="mt-4 mb-4">Apple ID Details</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Button
            onClick={() => {
              if (isEditMode) {
                handleCancelClick()
              } else {
                setIsEditMode(true)
              }
            }}
            variant={isEditMode ? 'secondary' : 'primary'}
            className="mb-4 me-2"
          >
            {isEditMode ? 'Cancel' : 'Edit'}
          </Button>
          {isEditMode && (
            <Button
              onClick={handleSaveClick}
              variant="success"
              className="mb-4"
            >
              Save
            </Button>
          )}
          <Table striped bordered hover responsive variant="dark">
            <tbody>
              <tr>
                <th>Mac Mini Username</th>
                <td>
                  {blueDeviceUser ? (
                    <Link
                      to={`/mac-mini-user/${blueDeviceUser.id}`}
                      style={{
                        color: 'light-green',
                        textDecoration: 'underline',
                      }}
                    >
                      {blueDeviceUser.friendly_id
                        .split('-')
                        .pop()
                        ?.toUpperCase() || blueDeviceUser.friendly_id}
                    </Link>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {isEditMode ? (
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  ) : (
                    formData.email
                  )}
                </td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>
                  {isEditMode ? (
                    <Form.Control
                      type="tel"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  ) : (
                    formData.phone_number
                  )}
                </td>
              </tr>
              <tr>
                <th>User ID</th>
                <td>
                  {isEditMode ? (
                    <Form.Control
                      type="number"
                      name="user_id"
                      value={formData.user_id}
                      onChange={handleChange}
                    />
                  ) : (
                    <Link
                      to={`/user/${formData.user_id}`}
                      style={{
                        color: 'light-green',
                        textDecoration: 'underline',
                      }}
                    >
                      {formData.user_id}
                    </Link>
                  )}
                </td>
              </tr>
              <tr>
                <th>Linq User</th>
                <td>{appleIdDetails?.user?.name || 'N/A'}</td>
              </tr>
              <tr>
                <th>Proxy URL</th>
                <td>{appleIdDetails?.proxy_url}</td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{new Date(appleIdDetails?.created_at).toLocaleString()}</td>
              </tr>
              <tr>
                <th>Updated At</th>
                <td>{new Date(appleIdDetails?.updated_at).toLocaleString()}</td>
              </tr>
              <tr>
                <th>Active</th>
                <td>
                  {isEditMode ? (
                    <Form.Check
                      type="checkbox"
                      name="active"
                      checked={formData.active}
                      onChange={handleChange}
                    />
                  ) : formData.active ? (
                    'Yes'
                  ) : (
                    'No'
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default AppleIdPage
