import AddUserToTeamOrOrgModal from 'components/add-user-to-team-or-org-modal'
import useOrganization from 'hooks/use-organization'
import useUpdateOrganization from 'hooks/use-update-organization'
import { Organization } from 'interfaces/organization'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Container, FormControl, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import OrganizationDetailsCard from 'components/organization-details-page/organization-details-card'
import { UpdateOrgSubscriptionIdModal } from 'components/organization-details-page/update-org-subscription-id-modal'
import { OverrideSubscriptionStatusModal } from 'components/organization-details-page/override-subscription-status-modal'
import { OrganizationStripeCard } from 'components/organization-details-page/organization-stripe-card'
import { OrganizationAddOnsCard } from 'components/organization-details-page/organization-add-ons-card'
import { OrganizationSettingsCard } from 'components/organization-details-page/organization-settings-card'
import OrganizationMembersTable from 'components/organization-details-page/organization-members-table'
import OrganizationHubspotNotes from 'components/organization-details-page/organization-hubspot-note'
import { OrganizationEntitlementsCard } from 'components/organization-details-page/organization-entitlements-card'
import { OrganizationTeamsCard } from 'components/organization-details-page/organization-teams-card'
import { OrganizationUnClaimedCard } from 'components/organization-details-page/organization-unclaimed-card'
import { EditOrgFieldsModal } from 'components/organization-details-page/edit-org-fields-modal'
import { OrganizationRecentCustomerIoEmail } from 'components/organization-details-page/organization-recent-customer-io-email'
import { OrganizationProductCredits } from 'components/organization-details-page/organization-product-credits-card'
import { OrganizationPrimaryUseCaseCard } from 'components/organization-details-page/organization-primary-use-case'
import OrganizationPhoneNumbersTable from 'components/organization-details-page/organization-phone-numbers-table'
import { useGetInternalAdmins } from 'hooks/use-get_internal-admins'

const OrganizationDetailPage = () => {
  const params = useParams<{ id: string }>()
  const { id: orgId } = params

  const [showAddUserToOrganizationModal, setShowAddUserToOrganizationModal] =
    useState(false)
  const [showUpdateAccountManagerModal, setShowUpdateAccountManagerModal] =
    useState(false)
  const [showSubscriptionIdUpdateModal, setShowSubscriptionIdUpdateModal] =
    useState(false)

  const [
    showUpdateSubscriptionStatusOverrideModal,
    setShowUpdateSubscriptionStatusOverrideModal,
  ] = useState(false)

  const [showUpdateSaleRepModal, setShowUpdateSaleRepModal] = useState(false)

  const [showEditOrgField, setShowEditOrgField] = useState(false)
  const [orgFieldModalParams, setOrgFieldModalParams] = useState<
    React.ComponentProps<typeof FormControl>
  >({
    value: '',
  })
  const [editableOrg, setEditableOrg] = useState<Partial<Organization>>()

  const { data: org, isFetching } = useOrganization(orgId)
  const { data: internalAdmins, isLoading: isInternalAdminsLoading } =
    useGetInternalAdmins()

  const { mutate: updateOrganization, isLoading: isUpdatingOrg } =
    useUpdateOrganization(orgId)

  const handleAddUserToOrganizationClicked = () => {
    setShowAddUserToOrganizationModal(true)
  }

  type Field = {
    fieldKey: string
    fieldLabel: string
    ariaId: string
    type: string
  }

  useEffect(() => {
    if (!org) return
    setEditableOrg(org)
  }, [org])

  const handleEditFieldClick = ({
    fieldKey,
    fieldLabel,
    ariaId,
    type,
  }: Field) => {
    setOrgFieldModalParams({
      title: fieldLabel,
      fieldKey,
      type,
      placeholder: fieldLabel,
      ariaLabel: fieldLabel,
      ariaDescribedBy: ariaId,
      buttonId: ariaId,
    })
    setShowEditOrgField(true)
  }

  const handleEditHubspotUrlClicked = () => {
    handleEditFieldClick({
      fieldKey: 'internal_crm_url',
      fieldLabel: 'Hubspot Url',
      ariaId: 'crm-url-button',
      type: 'url',
    })
  }

  const handleEditTierClicked = () => {
    handleEditFieldClick({
      fieldKey: 'organization_internal_metadatum_attributes.tier',
      fieldLabel: 'Tier',
      ariaId: 'tier-picker',
      type: 'string',
    })
  }

  const resetTier = () => {
    handleSaveFieldClick(
      {
        id: editableOrg?.id,
        organization_internal_metadatum_attributes: {
          tier: null,
        },
      },
      'Tier'
    )
  }

  const handleSaveFieldClick = (
    newOrg: Partial<Organization> = {},
    fieldName: string
  ) => {
    updateOrganization(
      { organization: newOrg },
      {
        onSuccess: () => {
          setShowEditOrgField(false)
          toast(`${fieldName} Updated!`, { icon: `👍` })
        },
      }
    )
  }

  const handleEditOrgFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableOrg((tempEditableOrg) => {
      const keyParts = orgFieldModalParams.fieldKey.split('.')
      const org: Record<string, any> = tempEditableOrg || {}
      if (keyParts.length === 1) {
        org[keyParts[0]] = e.target.value
        return org
      }

      let cur = org
      if (keyParts.length > 1) {
        for (let i = 0; i < keyParts.length - 1; i++) {
          cur[keyParts[i]] = cur[keyParts[i]] || {}
          cur = cur[keyParts[i]]
        }
      }

      cur[keyParts[keyParts.length - 1]] = e.target.value
      return org
    })
  }

  const isInHouseTrial =
    org?.organization_subscription?.subscription_status === 'trialing' &&
    org?.organization_subscription?.trial_end &&
    org?.organization_subscription?.subscription_id?.includes('trial')

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
        <Breadcrumb.Item active>
          {org?.name || 'Organization Details'}
        </Breadcrumb.Item>
      </Breadcrumb>
      {isFetching ? (
        <div className="m-2">Loading...</div>
      ) : (
        <>
          <Row className="mb-4">
            <Col lg="6">
              <OrganizationDetailsCard
                org={org}
                setShowUpdateSaleRepModal={setShowUpdateSaleRepModal}
                handleEditTierClicked={handleEditTierClicked}
                resetTier={resetTier}
                setShowUpdateAccountManagerModal={
                  setShowUpdateAccountManagerModal
                }
                setShowUpdateSubscriptionStatusOverrideModal={
                  setShowUpdateSubscriptionStatusOverrideModal
                }
                handleEditHubspotUrlClicked={handleEditHubspotUrlClicked}
                showUpdateSaleRepModal={showUpdateSaleRepModal}
                showUpdateAccountManagerModal={showUpdateAccountManagerModal}
                orgId={orgId}
                isInHouseTrial={!!isInHouseTrial}
                internalAdmins={internalAdmins}
                isInternalAdminsLoading={isInternalAdminsLoading}
              />
              <OrganizationSettingsCard org={org} orgId={orgId} />
            </Col>

            <Col lg="6">
              <OrganizationStripeCard
                org={org}
                setShowSubscriptionIdUpdateModal={
                  setShowSubscriptionIdUpdateModal
                }
                internalAdmins={internalAdmins}
                orgId={orgId}
                isInHouseTrial={!!isInHouseTrial}
              />
              <OrganizationAddOnsCard orgId={orgId} />
              <OrganizationEntitlementsCard
                org={org}
                handleSaveFieldClick={handleSaveFieldClick}
              />
              <OrganizationPrimaryUseCaseCard org={org} orgId={orgId} />
              <Col>
                <OrganizationTeamsCard org={org} orgId={orgId} />
                <OrganizationUnClaimedCard orgId={orgId} />
              </Col>
              <OrganizationProductCredits orgId={orgId} />
              <OrganizationRecentCustomerIoEmail orgId={orgId} />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <OrganizationHubspotNotes orgId={orgId} org={org} />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <OrganizationMembersTable
                handleAddUserToOrganizationClicked={
                  handleAddUserToOrganizationClicked
                }
                orgId={orgId}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <OrganizationPhoneNumbersTable orgId={orgId} />
            </Col>
          </Row>
        </>
      )}
      {/* Add User to Org Modal */}
      <AddUserToTeamOrOrgModal
        isOrg
        teamOrOrg={org}
        show={showAddUserToOrganizationModal}
        orgId={orgId}
        handleClose={() => setShowAddUserToOrganizationModal(false)}
      />

      <EditOrgFieldsModal
        showEditOrgField={showEditOrgField}
        setShowEditOrgField={setShowEditOrgField}
        orgFieldModalParams={orgFieldModalParams}
        handleSaveFieldClick={handleSaveFieldClick}
        isUpdatingOrg={isUpdatingOrg}
        editableOrg={editableOrg}
        handleEditOrgFieldChange={handleEditOrgFieldChange}
      />

      {org && (
        <UpdateOrgSubscriptionIdModal
          show={showSubscriptionIdUpdateModal}
          handleClose={() => setShowSubscriptionIdUpdateModal(false)}
          org={org}
          setShowSubscriptionIdUpdateModal={setShowSubscriptionIdUpdateModal}
        />
      )}
      <OverrideSubscriptionStatusModal
        show={showUpdateSubscriptionStatusOverrideModal}
        handleClose={() => setShowUpdateSubscriptionStatusOverrideModal(false)}
        orgId={orgId}
        organizationSubscription={org?.organization_subscription}
      />
    </Container>
  )
}

export default OrganizationDetailPage
