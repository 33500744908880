import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import { organizationsKeys } from 'utils/query-keys'
import { PhoneNumber } from 'interfaces/phone-number'

const orgProductCredits = async (orgId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/api/v3/organizations/${orgId}/phone_numbers`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.phone_numbers as PhoneNumber[]
}

const useOrganizationProductCredits = (orgId: string) => {
  return useQuery(
    organizationsKeys.phoneNumbers.detail(orgId),
    () => orgProductCredits(orgId),
    {
      enabled: !!orgId,
    }
  )
}

export default useOrganizationProductCredits
