import { useMutation } from 'react-query'
import { store } from 'index'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'

interface RefreshResponse {
  success: boolean
  message: string
}

const refreshAppleId = async (userId: number): Promise<RefreshResponse> => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v3/mac_mini_users/${userId}/refresh`,
    {
      method: API_METHODS.POST,
      headers: {
        ...apiHeaders(store.getState()),
        'Content-Type': 'application/json',
      },
    }
  )

  if (!response.ok) {
    const errorData = await response.json()
    const errorMessage =
      errorData.error?.message || 'Failed to refresh Apple ID'
    throw new Error(errorMessage)
  }

  const responseData: RefreshResponse = await response.json()
  return responseData
}

const useRefreshAppleId = () => {
  return useMutation(refreshAppleId)
}

export default useRefreshAppleId
