import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrors } from 'utils/handle-errors'
import {
  organizationsKeys,
  organizationSubscriptionItemsKeys,
} from 'utils/query-keys'
import { toast } from 'react-hot-toast'

interface UpsertSubscriptionItemsData {
  linq_one_quantity: string
  linq_blue_quantity: string
  linq_one_price_id: string
  linq_blue_price_id: string
  sale_rep_id: string
}

const upsertSubscriptionItems = async (
  orgId: string,
  data: UpsertSubscriptionItemsData
) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/organizations/${orgId}/upsert_subscription_items`,
    {
      method: API_METHODS.PUT,
      headers: apiHeaders(store.getState()),
      body: JSON.stringify(data),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json
}

const useUpsertSubscriptionItems = (orgId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (data: UpsertSubscriptionItemsData) => upsertSubscriptionItems(orgId, data),
    {
      onError: (e: Error) => {
        toast(`Error upserting subscription items ${e.message}`)
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          organizationSubscriptionItemsKeys.detail(orgId)
        )
        queryClient.invalidateQueries(organizationsKeys.detail(orgId))
      },
    }
  )
}

export default useUpsertSubscriptionItems
