import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Button,
  Accordion,
  Alert,
  InputGroup,
  FormControl,
  Row,
  Dropdown,
} from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'
import useUpdateUser from 'hooks/use-update-user'
import UserSettingDropDown from './user-setting-dropdown'
import { LINQ_EMAIL_DOMAIN } from 'constants/empty'
import { User } from 'interfaces/user'
import toast from 'react-hot-toast'
import useUpdateUserMetaData from 'hooks/use-update-user-metadata'
import { useEntitlementGroupings } from 'hooks/use-entitlement-groupings'
import useEntitlementGrouping from 'hooks/use-entitlement-grouping'

const UserSettingCard = ({ user }: { user: User }) => {
  const [maxPages, setMaxPages] = useState<string>()
  const [slackId, setSlackId] = useState<string>()
  const updateUser = useUpdateUser(user?.uuid)
  const updateUserMetaData = useUpdateUserMetaData(user?.uuid, user?.id)
  const { data: entitlementGroupings } = useEntitlementGroupings()

  const { data: entitlementGrouping } = useEntitlementGrouping(
    user?.entitlement_grouping_id
  )

  const handleUpdateUserToLinqPro = ({
    updatedField,
  }: {
    updatedField: boolean
  }) => {
    updateUser.mutate(
      { is_pro: updatedField },
      {
        onSuccess: () => {
          toast('Linq Pro Updated', { icon: `👍` })
        },
      }
    )
  }

  const handleUpdateUserToLinqProPlus = ({
    updatedField,
  }: {
    updatedField: boolean
  }) => {
    updateUser.mutate(
      { pro_plus: updatedField },
      {
        onSuccess: () => {
          toast('Linq Pro Plus Updated', { icon: `👍` })
        },
      }
    )
  }

  const handleUpdateEntitlementGrouping = (entitlementGroupingId: number) => {
    updateUser.mutate(
      { entitlement_grouping_id: entitlementGroupingId },
      {
        onSuccess: () => {
          toast('Entitlement Grouping Updated', { icon: `👍` })
        },
      }
    )
  }

  const handleUpdateUserToInternalAdmin = ({
    updatedField,
  }: {
    updatedField: boolean
  }) => {
    if (updatedField) {
      if (
        window.confirm(
          `Are you sure you want to make ${user.name} as LINQ INTERNAL ADMIN?`
        )
      ) {
        updateUser.mutate(
          { linq_internal_admin: updatedField },
          {
            onSuccess: () => {
              toast('Update user to Linq internal admin', { icon: `👍` })
            },
          }
        )
      }
    } else {
      updateUser.mutate(
        { linq_internal_admin: updatedField },
        {
          onSuccess: () => {
            toast('Remove user from Linq internal admin', { icon: `👍` })
          },
        }
      )
    }
  }

  const handleUpdateUserToHaveUnlimitedPage = ({
    updatedField,
  }: {
    updatedField: boolean
  }) => {
    updateUser.mutate({ can_have_unlimited_pages: updatedField })
  }

  const handleUpdateNumberOfAllowedPages = (e: React.FormEvent) => {
    e.preventDefault()
    if (maxPages) {
      updateUserMetaData.mutate(
        {
          max_pages_override: parseInt(maxPages),
        },
        {
          onSuccess: () => {
            toast(`Successfully update user's max pages`, { icon: `👍` })
          },
        }
      )
    }
  }

  const handleUpdateSlackId = (e: React.FormEvent) => {
    e.preventDefault()
    if (slackId) {
      updateUser.mutate(
        {
          internal_slack_id: slackId,
        },
        {
          onSuccess: () => {
            toast(`Successfully updated Slack ID! 🎉`, { icon: `🎈` })
          },
        }
      )
    }
  }

  useEffect(() => {
    if (user?.max_pages) {
      setMaxPages(user?.max_pages?.toString())
    }
    if (user?.internal_slack_id) {
      setSlackId(user.internal_slack_id)
    }
  }, [user])

  return (
    <Col md="12">
      <Card className="mt-4">
        <Card.Header>
          <h4>User Setting</h4>
        </Card.Header>
        <Card.Body>
          <UserSettingDropDown
            label="Linq Pro ? :"
            handleUpdate={handleUpdateUserToLinqPro}
            value={user?.is_pro}
            setTo="Set User to Pro"
            remove="Remove User from Pro"
          />
          <UserSettingDropDown
            label="Linq Pro Plus? :"
            handleUpdate={handleUpdateUserToLinqProPlus}
            value={user?.is_pro_plus}
            setTo="Set User to Pro Plus"
            remove="Remove User from Pro Plus"
          />
          <UserSettingDropDown
            label="Internal Admin ? :"
            handleUpdate={handleUpdateUserToInternalAdmin}
            value={user?.is_admin}
            setTo="Set To Admin role"
            remove="Remove Admin role"
            isDisabled={!user?.email?.includes(LINQ_EMAIL_DOMAIN)}
          />
          <UserSettingDropDown
            label="Unlimited Pages ? :"
            handleUpdate={handleUpdateUserToHaveUnlimitedPage}
            value={user?.can_have_unlimited_pages}
            setTo="Enable User to Have Unlimited Page"
            remove="Disable User to Have Unlimited Page"
          />
          <form onSubmit={handleUpdateNumberOfAllowedPages}>
            <label htmlFor="maxPagesInput">Max Pages Override</label>
            <InputGroup className="mb-4 mt-2">
              <FormControl
                id="maxPagesInput"
                autoComplete="off"
                onChange={(e) => setMaxPages(e.target.value)}
                value={maxPages}
                type="phone"
                placeholder="Enter User's Max Pages"
                aria-label="User's Max Pages"
              />
              <Button type="submit">Save</Button>
            </InputGroup>
          </form>
          {user?.is_admin && (
            <form onSubmit={handleUpdateSlackId}>
              <label htmlFor="slackIdInput">InternalSlack ID</label>
              <InputGroup className="mb-4 mt-2">
                <FormControl
                  id="slackIdInput"
                  autoComplete="off"
                  onChange={(e) => setSlackId(e.target.value)}
                  value={slackId}
                  placeholder="Enter User's Slack ID"
                  aria-label="User's Slack ID"
                />
                <Button type="submit">Save</Button>
              </InputGroup>
            </form>
          )}
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <h4>Entitlement Grouping</h4>
        </Card.Header>
        <Card.Body>
          <Row className="mb-2">
            <Col lg="6" className="mt-2">
              <Card>
                <Dropdown className="my-2">
                  <Dropdown.Toggle variant="info" className="p-1">
                    {entitlementGrouping?.title ||
                      'Select Entitlement Grouping'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {entitlementGroupings?.map((entitlementGroupingEntity) => (
                      <Dropdown.Item
                        onClick={() => {
                          handleUpdateEntitlementGrouping(
                            entitlementGroupingEntity.id
                          )
                        }}
                        key={entitlementGroupingEntity.id}
                      >
                        {entitlementGroupingEntity.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              View All User Data
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <code>
                <JSONPretty data={user} />
              </code>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Alert />
    </Col>
  )
}

export default UserSettingCard
