import { useMutation } from 'react-query'
import { store } from 'index'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { AppleId } from 'interfaces/apple-id'

interface UpdateAppleIdParams {
  id: string
  data: Partial<AppleId>
}

const updateAppleId = async ({ id, data }: UpdateAppleIdParams) => {
  const payload = { apple_id: data }

  const response = await fetch(`${BASE_API_URL}/admin/api/v2/apple_ids/${id}`, {
    method: API_METHODS.PUT,
    headers: {
      ...apiHeaders(store.getState()),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    const errorData = await response.json()
    const errorMessage =
      errorData.error?.messages?.[0] ||
      errorData.error?.message ||
      'Failed to update Apple ID'
    throw new Error(errorMessage)
  }

  const responseData = await response.json()
  return responseData
}

const useUpdateAppleId = () => {
  return useMutation(updateAppleId)
}

export default useUpdateAppleId
