import { useMutation, useQueryClient } from 'react-query'
import { store } from 'index'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'

const updateMacMiniUser = async ({ id, data }: { id: string; data: any }) => {
  const response = await fetch(
    `${BASE_API_URL}/admin/api/v3/mac_mini_users/${id}`,
    {
      method: API_METHODS.PUT,
      headers: {
        ...apiHeaders(store.getState()),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  )
  handleErrors(response)
  const json = await response.json()
  return json.data
}

const useUpdateMacMiniUser = () => {
  const queryClient = useQueryClient()

  return useMutation(updateMacMiniUser, {
    onSuccess: (data) => {
      const updatedUser = data.mac_mini_user
      if (!updatedUser || !updatedUser.id) {
        console.error('Unexpected response structure:', data)
        return
      }

      queryClient.setQueryData('macMiniUsers', (oldData: any) => {
        if (!oldData) return oldData

        return oldData.map((user: any) => {
          if (user.id.toString() === updatedUser.id.toString()) {
            return {
              ...user,
              ...updatedUser,
            }
          }
          return user
        })
      })

      queryClient.refetchQueries('allAppleIds', { active: true })
      queryClient.refetchQueries('availableAppleIds', { active: true })
    },
  })
}

export default useUpdateMacMiniUser
