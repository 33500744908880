import useDeleteUserOrganization from 'hooks/use-delete-user-organization'
import useDeletePhoneNumber from 'hooks/use-delete-worknumber'
import { useGetOrganizationUsers } from 'hooks/use-get-organization-users'
import useOrganizationPhoneNumbers from 'hooks/use-organization-phone-numbers'
import { PhoneNumber } from 'interfaces/phone-number'
import { UserOrganization } from 'interfaces/user-organization'
import { useEffect, useState } from 'react'
import { Card, Button, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { ID } from 'utils/types'

interface OrganizationMembersTableProps {
  orgId: string
}
const OrganizationPhoneNumbersTable = ({
  orgId,
}: OrganizationMembersTableProps) => {
  const history = useHistory()
  const [roleFilterValue, setRoleFilterValue] = useState('all')
  const {
    data: userOrganizationsData,
    isFetching: isUserOrganizationsFetching,
  } = useGetOrganizationUsers(orgId)
  const { data: phoneNumbers, isFetching: isPhoneNumbersFetching } =
    useOrganizationPhoneNumbers(orgId)
  const userOrganizations = userOrganizationsData?.data?.user_organizations
  const deletePhoneNumber = useDeletePhoneNumber()

  const handleDeletePhoneNumber = (phoneNumber: PhoneNumber) => {
    if (
      window.confirm(
        `⚠️ Deleting this phone number will make it unavailable for the user. Before proceeding, please confirm it's necessary.\n\n🚨 If an organization deletes a number and claims a new one, we'll still be charged for both.`
      )
    ) {
      deletePhoneNumber.mutate(phoneNumber.id)
    }
  }
  return (
    <Card className="mt-4">
      {isPhoneNumbersFetching ? (
        <div className="m-2">Loading...</div>
      ) : (
        <>
          <Card.Header>
            <h4>Organization Phone Numbers</h4>
          </Card.Header>
          <Card.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <Table striped bordered hover responsive variant="dark">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>User</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {phoneNumbers &&
                  userOrganizations &&
                  phoneNumbers.map((phoneNumber, index) => {
                    const name =
                      userOrganizations.find(
                        (userOrganization) =>
                          userOrganization.user.id === phoneNumber.user_id
                      )?.user.name || 'AVAILABLE'
                    return (
                      <tr key={index}>
                        <td>{phoneNumber.number}</td>
                        <td>{name}</td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleDeletePhoneNumber(phoneNumber)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </>
      )}
    </Card>
  )
}

export default OrganizationPhoneNumbersTable
