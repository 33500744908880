import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Table,
  Breadcrumb,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useGetMacMiniUsers from 'hooks/use-get-mac-mini-users'
import { MacMiniUser } from 'interfaces/mac-mini-user'

const BluePage = () => {
  const { data, isLoading, error } = useGetMacMiniUsers()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredData, setFilteredData] = useState<MacMiniUser[] | undefined>(
    data
  )
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!data) return
    setFilteredData(data)
  }, [data])

  useEffect(() => {
    searchInputRef.current?.focus()
  }, [])

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    const query = searchQuery.toLowerCase()
    const filtered = data?.filter(
      (macMiniUser: MacMiniUser) =>
        macMiniUser.friendly_id.toLowerCase().includes(query) ||
        macMiniUser.mac_mini.id.toString().toLowerCase().includes(query) ||
        (macMiniUser.apple_id?.phone_number?.toLowerCase().includes(query) ??
          false) ||
        (macMiniUser.apple_id?.user?.name?.toLowerCase().includes(query) ??
          false)
    )
    setFilteredData(filtered)
  }

  if (isLoading) return <div>Loading...</div>
  if (error) {
    const errorMessage = (error as Error).message
    return <div>Error: {errorMessage}</div>
  }

  return (
    <Container className="mb-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Blue</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="mt-4 mb-4">Blue</h1>
      <InputGroup className="mb-4 mt-4">
        <FormControl
          ref={searchInputRef}
          autoComplete="off"
          onChange={handleSearchInputChange}
          onKeyDown={handleKeyDown}
          value={searchQuery}
          type="text"
          placeholder="Search by Friendly ID, Phone Number, Mac Mini, or Linq User"
          aria-label="Search Mac Mini Users"
        />
        <Button variant="success" onClick={handleSearch}>
          <ion-icon name="search" />
        </Button>
      </InputGroup>
      <Table striped bordered hover responsive variant="dark">
        <thead>
          <tr>
            <th>Mac Mini</th>
            <th>Mac Mini Username</th>
            <th>Phone #</th>
            <th>Linq User</th>
            <th>Server Address</th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((macMiniUser: MacMiniUser) => (
            <tr key={macMiniUser.id}>
              <td>
                <Link
                  to={`/mac-mini/${macMiniUser.mac_mini.id}`}
                  style={{
                    display: 'block',
                    color: 'light-green',
                    textDecoration: 'underline',
                  }}
                >
                  M{macMiniUser.mac_mini.friendly_name}
                </Link>
              </td>
              <td>
                <Link
                  to={`/mac-mini-user/${macMiniUser.id}`}
                  style={{
                    display: 'block',
                    color: 'light-green',
                    textDecoration: 'underline',
                  }}
                >
                  {macMiniUser.friendly_id.split('-').pop()?.toUpperCase() ||
                    macMiniUser.friendly_id}
                </Link>
              </td>
              <td>
                {macMiniUser.apple_id ? (
                  <Link
                    to={`/apple-id/${macMiniUser.apple_id.id}`}
                    style={{
                      display: 'block',
                      color: 'light-green',
                      textDecoration: 'underline',
                    }}
                  >
                    {macMiniUser.apple_id.phone_number
                      ? macMiniUser.apple_id.phone_number
                      : 'N/A'}
                  </Link>
                ) : (
                  'N/A'
                )}
              </td>
              <td>
                {macMiniUser.apple_id?.user ? (
                  <Link
                    to={`/user/${macMiniUser.apple_id.user.id}`}
                    style={{
                      display: 'block',
                      color: 'light-green',
                      textDecoration: 'underline',
                    }}
                  >
                    {macMiniUser.apple_id.user.name}
                  </Link>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{macMiniUser.server_address}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default BluePage
