import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NavigationBar from 'components/navigation-bar'
import AdminAccountPage from 'pages/admin-account-page'
import HomePage from 'pages/home-page'
import InsightsPage from 'pages/insights-page'
import LoginConfirmationPage from 'pages/login-confirmation-page'
import LoginPage from 'pages/login-page'
import ProductDetailPage from 'pages/product-detail-page'
import ProductsPage from 'pages/products-page'
import TeamsLinkBuilderPage from 'pages/teams-link-builder-page'
import OrganizationsPage from 'pages/organizations-page'
import OrganizationDetailPage from 'pages/organization-detail-page'
import OrganizationBulkUploadsPage from 'pages/organization-bulk-uploads-page'
import TeamDetailPage from 'pages/team-detail-page'
import UserDetailPage from 'pages/user-detail-page'
import UsersPage from 'pages/users-page'
import ProductEditPage from 'pages/product-edit-page'
import ZakekeOrdersPage from 'pages/zakeke-orders-page'
import PrintFilesPage from 'pages/print-files-page'
import ProductCreatePage from 'pages/product-create-page'
import ZakekeOrderEditPage from 'pages/zakeke-order-edit-page'
import ContactPageDetails from 'pages/contact-detail-page'
import OrganizationIntegrationConnectionsPage from 'pages/organization-integration-connections-page'
import { Toaster } from 'react-hot-toast'
import OrganizationCreatePage from 'pages/organization-create-page'
import WeeklyAnalyticsEmailPage from 'pages/weekly-analytics-email'
import BugReporterPage from 'pages/bug-reporter-page'
import { RootState } from 'index'
import OrganizationSSOPage from 'pages/organization-sso-page'
import StripeProductsListPage from 'pages/stripe-products-list-page'
import EntitlementGroupingsPage from 'pages/entitlement-groupings-page'
import EntitlementGroupingPage from 'pages/entitlement-grouping-page'
import ZakekeProductsPage from 'pages/zakeke-products-page'
import ZakekeProductCreatePage from 'pages/zakeke-product-create-page'
import ProductJigTemplateCreatePage from 'pages/product-jig-template-create-page'
import ProductJigTemplatesPage from 'pages/product-jig-templates-page'
import ProductJigTemplateEditPage from 'pages/product-jig-template-edit-page'
import ZakekeProductEditPage from 'pages/zakeke-product-edit-page'
import CreateEntitlementGroupingPage from 'pages/create-entitlement-grouping-page'
import UserScriptsPage from 'pages/user-scripts-page'
import ProductQrCodeGenerationPage from 'pages/product-qr-code-generation-page'
import BluePage from 'pages/blue-page'
import AppleIdPage from 'pages/apple-id-details-page'
import MacMiniDetailsPage from 'pages/mac-mini-details-page'
import MacMiniUserDetailsPage from 'pages/mac-mini-user-details-page'
const App = () => {
  const currentUser = useSelector((state: RootState) => state.user.currentUser)

  return (
    <>
      <Router>
        <NavigationBar />
        {currentUser ? (
          <Switch>
            <Route path="/home">
              <HomePage />
            </Route>
            <Route path="/products" exact>
              <ProductsPage />
            </Route>
            <Route path="/stripe-products-list">
              <StripeProductsListPage />
            </Route>
            <Route path="/create-product-jig-template">
              <ProductJigTemplateCreatePage />
            </Route>
            <Route path="/product-jig-templates/:productJigTemplateId/edit">
              <ProductJigTemplateEditPage />
            </Route>
            <Route path="/product-jig-templates">
              <ProductJigTemplatesPage />
            </Route>
            <Route path="/products/:productId" exact>
              <ProductDetailPage />
            </Route>
            <Route path="/products/:productId/qr-code-generation" exact>
              <ProductQrCodeGenerationPage />
            </Route>
            <Route path="/create-linq-product" exact>
              <ProductCreatePage />
            </Route>
            <Route path="/products/:productId/edit" exact>
              <ProductEditPage />
            </Route>
            <Route path="/zakeke_orders/:orderId/edit">
              <ZakekeOrderEditPage />
            </Route>
            <Route path="/zakeke_orders">
              <ZakekeOrdersPage />
            </Route>
            <Route path="/zakeke_products/:zakekeProductId/edit" exact>
              <ZakekeProductEditPage />
            </Route>
            <Route path="/zakeke_products">
              <ZakekeProductsPage />
            </Route>
            <Route path="/create-zakeke-product">
              <ZakekeProductCreatePage />
            </Route>
            <Route path="/print_files">
              <PrintFilesPage />
            </Route>
            <Route path="/users">
              <UsersPage />
            </Route>
            <Route path="/blue">
              <BluePage />
            </Route>
            <Route path="/user/:uuid" exact>
              <UserDetailPage />
            </Route>
            <Route path="/user/:uuid/scripts" exact>
              <UserScriptsPage />
            </Route>
            <Route path="/insights">
              <InsightsPage />
            </Route>
            <Route path="/organizations" exact>
              <OrganizationsPage />
            </Route>
            <Route path="/organizations/new" exact>
              <OrganizationCreatePage />
            </Route>
            <Route path="/organizations/:id" exact>
              <OrganizationDetailPage />
            </Route>
            <Route path="/organizations/:id/bulk-uploads" exact>
              <OrganizationBulkUploadsPage />
            </Route>
            <Route path="/organizations/:id/integrations" exact>
              <OrganizationIntegrationConnectionsPage />
            </Route>
            <Route path="/organizations/:id/sso" exact>
              <OrganizationSSOPage />
            </Route>
            <Route path="/organizations/:orgId/teams/:teamId" exact>
              <TeamDetailPage />
            </Route>
            <Route path="/teams-purchase-link">
              <TeamsLinkBuilderPage />
            </Route>
            <Route path="/contacts/:contactId">
              <ContactPageDetails />
            </Route>
            <Route path="/account">
              <AdminAccountPage />
            </Route>
            <Route path="/emails/weekly-analytics-email">
              <WeeklyAnalyticsEmailPage />
            </Route>
            <Route path="/bug-reporter">
              <BugReporterPage />
            </Route>
            <Route path="/entitlement-groupings" exact>
              <EntitlementGroupingsPage />
            </Route>
            <Route path="/create-entitlement-grouping" exact>
              <CreateEntitlementGroupingPage />
            </Route>
            <Route path="/entitlement-groupings/:entitlementGroupingId" exact>
              <EntitlementGroupingPage />
            </Route>
            <Route path="/apple-id/:id" exact>
              <AppleIdPage />
            </Route>
            <Route path="/mac-mini/:id" exact>
              <MacMiniDetailsPage />
            </Route>
            <Route path="/mac-mini-user/:id" exact>
              <MacMiniUserDetailsPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/confirmation/:authConfirmationUUID">
              <LoginConfirmationPage />
            </Route>
            <Route path="/">
              <LoginPage />
            </Route>
          </Switch>
        )}
        <Toaster />
      </Router>
    </>
  )
}

export default App
