import { useQuery } from 'react-query'
import { store } from 'index'
import { BASE_API_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { handleErrors } from 'utils/handle-errors'

const fetchAppleId = async (appleId: string) => {
  let response = await fetch(
    `${BASE_API_URL}/admin/api/v2/apple_ids/${appleId}`,
    {
      method: API_METHODS.GET,
      headers: apiHeaders(store.getState()),
    }
  )
  response = handleErrors(response)
  const json = await response.json()
  return json.data.apple_id
}

const useGetAppleId = (appleId: string) => {
  return useQuery(
    ['apple_id', appleId], // Using an array to uniquely identify the query
    () => fetchAppleId(appleId),
    {
      enabled: !!appleId, // Only run the query if an appleId is provided
    }
  )
}

export default useGetAppleId
