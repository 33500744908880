import { LinqBlueExpandModal } from 'components/linq-blue-expand-modal'
import useOrganizationSubscriptionItems from 'hooks/use-organization-subscription-items'
import { Organization } from 'interfaces/organization'
import { User } from 'interfaces/user'
import { useState } from 'react'
import { Card, Button, Table } from 'react-bootstrap'

interface OrganizationStripeCardProps {
  setShowSubscriptionIdUpdateModal: (show: boolean) => void
  org?: Organization
  orgId: string
  isInHouseTrial: boolean
  internalAdmins?: User[]
}

export const OrganizationStripeCard = ({
  setShowSubscriptionIdUpdateModal,
  org,
  orgId,
  isInHouseTrial,
  internalAdmins,
}: OrganizationStripeCardProps) => {
  const [showBlueExpandModal, setShowBlueExpandModal] = useState(false)
  const { data: subscriptionItems } = useOrganizationSubscriptionItems(orgId)
  const orgSub = org?.organization_subscription
  const paymentMethodUpdatedRequired =
    orgSub?.subscription_status === 'unpaid' ||
    orgSub?.subscription_status === 'past_due'
  const startNewSubscription = orgSub?.subscription_status === 'canceled'

  return (
    <>
      <Card className="mt-4">
        <Card.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: '24px',
            }}
          >
            Stripe Subscription Details
          </p>
          <span
            style={{
              cursor: 'pointer',
              backgroundColor: '#303030',
              color: '#fff',
              padding: '8px',
              fontSize: '16px',
              borderRadius: '4px',
            }}
            onClick={() => setShowSubscriptionIdUpdateModal(true)}
          >
            {org?.organization_subscription?.subscription_id}
          </span>
        </Card.Header>
        <Card.Body>
          {!isInHouseTrial && (
            <Button
              className="mb-4"
              style={{
                width: '100%',
              }}
              onClick={() => {
                window.open(
                  `https://dashboard.stripe.com/subscriptions/${org?.organization_subscription?.subscription_id}`,
                  '_blank'
                )
              }}
            >
              Go to Stripe
            </Button>
          )}
          <Table striped bordered hover responsive variant="dark">
            <thead>
              <tr>
                <th>Product</th>
                <th>Status</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {subscriptionItems &&
                subscriptionItems.length > 0 &&
                subscriptionItems.map((item) => (
                  <tr key={item.product_name}>
                    <td>{item.product_name}</td>
                    <td>{item.status}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
        {orgSub && (
          <Card.Footer>
            <Button
              variant="danger"
              onClick={() => setShowBlueExpandModal(true)}
              style={{
                width: '100%',
              }}
              disabled={paymentMethodUpdatedRequired || startNewSubscription}
            >
              Upgrade to Blue
            </Button>
            <div>
              {paymentMethodUpdatedRequired && (
                <p>
                  This subscription requires a payment method update. Please ask
                  the customer to update their payment information before
                  proceeding with the Linq Blue upgrade.
                </p>
              )}
              {startNewSubscription && (
                <p>
                  Linq Blue upgrade is not available for canceled subscriptions.
                  A new purchase link must be generated to restart this
                  organization's subscription.
                </p>
              )}
            </div>
          </Card.Footer>
        )}
      </Card>
      {internalAdmins && org && subscriptionItems && (
        <LinqBlueExpandModal
          show={showBlueExpandModal}
          handleClose={() => setShowBlueExpandModal(false)}
          orgId={orgId}
          internalAdmins={internalAdmins}
          subscriptionItems={subscriptionItems}
          org={org}
        />
      )}
    </>
  )
}
