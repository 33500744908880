import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { User } from 'interfaces/user'
import { StripeSubscriptionItem } from 'interfaces/stripe-subscription-item'
import { useAdminStripeAvailablePrices } from 'hooks/use-admin-stripe'
import { Organization } from 'interfaces/organization'
import { useSelector } from 'react-redux'
import { RootState } from 'index'
import { LINQ_ONE_PRODUCT_ID, LINQ_BLUE_PRODUCT_ID } from 'constants/env'
import useUpsertSubscriptionItems from 'hooks/use-upsert-subscription-items'

export const LinqBlueExpandModal = ({
  show,
  handleClose,
  orgId,
  internalAdmins,
  subscriptionItems,
  org,
}: {
  show: boolean
  handleClose: () => void
  orgId: string
  internalAdmins?: User[]
  subscriptionItems?: StripeSubscriptionItem[]
  org: Organization
}) => {
  const currentUser = useSelector<RootState, User | null>(
    (state) => state.user.currentUser
  )
  const organizationSubscription = org.organization_subscription
  const [saleRepId, setSaleRepId] = useState(currentUser?.id)
  const [linqOneQuantity, setLinqOneQuantity] = useState<string>('0')
  const [linqBlueQuantity, setLinqBlueQuantity] = useState<string>('0')
  const [linqOnePriceId, setLinqOnePriceId] = useState<string>('')
  const [linqBluePriceId, setLinqBluePriceId] = useState<string>('')

  const { data: linqBluePrices } = useAdminStripeAvailablePrices(
    LINQ_BLUE_PRODUCT_ID || ''
  )
  const { data: linqOnePrices } = useAdminStripeAvailablePrices(
    LINQ_ONE_PRODUCT_ID || ''
  )

  const {
    mutate: upsertSubscriptionItems,
    isLoading: isUpsertingSubscriptionItems,
  } = useUpsertSubscriptionItems(orgId)

  useEffect(() => {
    if (!subscriptionItems || !internalAdmins || !saleRepId) return
    const linqOne = subscriptionItems.find(
      (item) => item.product_id === LINQ_ONE_PRODUCT_ID
    )
    if (linqOne) {
      setLinqOnePriceId(linqOne.price_id)
      setLinqOneQuantity(linqOne.quantity.toString())
    }
    const linqBlue = subscriptionItems.find(
      (item) => item.product_id === LINQ_BLUE_PRODUCT_ID
    )
    if (linqBlue) {
      setLinqBluePriceId(linqBlue.price_id)
      setLinqBlueQuantity(linqBlue.quantity.toString())
    }
  }, [subscriptionItems])

  useEffect(() => {
    if (!organizationSubscription?.sale_rep_id || !currentUser?.id) return
    const saleRep = organizationSubscription.sale_rep_id || currentUser?.id
    setSaleRepId(saleRep.toString())
    setLinqOneQuantity(organizationSubscription?.seat_count?.toString())
  }, [organizationSubscription, internalAdmins])

  const handleSave = () => {
    const linqOnePrice = linqOnePrices?.find(
      (price) => price.id === linqOnePriceId
    )
    const linqBluePrice = linqBluePrices?.find(
      (price) => price.id === linqBluePriceId
    )

    if (Number(linqOneQuantity) < organizationSubscription?.seat_count) {
      window.alert(
        'Linq One quantity can not be less than the current seat count'
      )
      return
    }
    if (Number(linqOneQuantity) < Number(linqBlueQuantity)) {
      window.alert('Linq One quantity can not be less than Linq Blue quantity')
      return
    }
    if (
      linqOnePrice?.recurring?.interval !== linqBluePrice?.recurring?.interval
    ) {
      window.alert('Linq One and Linq Blue must have the same billing interval')
      return
    }

    const confirm = window.confirm(
      'Make sure the customer has confirmed they want their card to be charged for this new subscription'
    )
    if (!confirm) return

    upsertSubscriptionItems(
      {
        linq_one_quantity: linqOneQuantity,
        linq_blue_quantity: linqBlueQuantity,
        linq_one_price_id: linqOnePriceId,
        linq_blue_price_id: linqBluePriceId,
        sale_rep_id: saleRepId || '',
      },
      {
        onSuccess: () => {
          window.alert('Subscription items updated successfully')
          handleClose()
        },
      }
    )
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>Expand Linq Blue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-4">
          {/* Linq One Section */}
          <div className="p-3 row align-items-center">
            <div className="col-md-2">Linq One</div>

            <div className="col-md-5">
              <Form.Group controlId="linqOneQuantity">
                <Form.Control
                  type="number"
                  min="1"
                  placeholder="Enter quantity"
                  value={linqOneQuantity}
                  onChange={(e) => setLinqOneQuantity(e.target.value)}
                  style={{ backgroundColor: '#444', color: 'white' }}
                />
              </Form.Group>
            </div>
            <div className="col-md-5">
              <div>
                <select
                  id="linqOnePriceSelect"
                  value={linqOnePriceId}
                  className="form-select"
                  onChange={(e) => setLinqOnePriceId(e.target.value)}
                >
                  <option value="">Select Price</option>
                  {linqOnePrices?.map((price) => (
                    <option value={price.id}>
                      {price.metadata.internal_description
                        ? price.metadata.internal_description
                        : `$${price.unit_amount / 100} / ${
                            price?.recurring?.interval
                          }`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Linq Blue Section */}
          <div className="p-3 row align-items-center">
            <div className="col-md-2">Linq Blue</div>

            <div className="col-md-5">
              <Form.Group controlId="linqBlueQuantity">
                <Form.Control
                  type="number"
                  min="1"
                  placeholder="Enter quantity"
                  value={linqBlueQuantity}
                  onChange={(e) => setLinqBlueQuantity(e.target.value)}
                  style={{ backgroundColor: '#444', color: 'white' }}
                />
              </Form.Group>
            </div>
            <div className="col-md-5">
              <div>
                <select
                  id="linqBluePriceSelect"
                  value={linqBluePriceId}
                  className="form-select"
                  onChange={(e) => setLinqBluePriceId(e.target.value)}
                >
                  <option value="">Select Price</option>
                  {linqBluePrices?.map((price) => (
                    <option value={price.id}>
                      {price.metadata.internal_description
                        ? price.metadata.internal_description
                        : `$${price.unit_amount / 100} / ${
                            price?.recurring?.interval
                          }`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Sale Rep Section */}
          <div className="p-3 row align-items-center">
            <div className="col-md-12">
              <div className="form-floating mt-4 mb-4">
                <select
                  id="saleRepSelect"
                  value={saleRepId}
                  onChange={(e) => setSaleRepId(e.target.value)}
                  className="form-select"
                >
                  {internalAdmins?.map((admin: User) => (
                    <option key={`${admin.uuid}-${admin.id}`} value={admin.id}>
                      {admin.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="saleRepSelect">Sale Rep</label>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSave} disabled={isUpsertingSubscriptionItems}>
          {isUpsertingSubscriptionItems
            ? 'Saving to stripe'
            : 'Save & Charge New Subscription Items'}
        </Button>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isUpsertingSubscriptionItems}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
